<template>
  <div class="home-iot-info">
    <div class="home-iot-info-content-box">
      <div class="home-iot-info-content">
        <div class="home-iot-info-head">
          <div class="home-iot-info-head-img">
            <span><v-img :attachment-id="iotInfo.productImg" :default-img="require('@/assets/img/kctp.png')" /></span>
          </div>
          <div class="home-iot-info-head-detail">
            <div class="home-iot-info-head-title">
              <span>
                <template v-if="iotInfo.isHarmony == 1">
                  <span v-if="iotInfo.unhmState == '01'">迁移沟通中</span>
                  <span v-if="iotInfo.unhmState == '02'">产品研发中</span>
                  <span v-if="iotInfo.unhmState == '03'">版本测试中</span>
                </template>
                {{iotInfo.name}}
              </span>
            </div>
            <div class="home-iot-info-head-desc">
              <div class="home-iot-info-head-desc-text">
                <div v-if="iotInfo.sceneTagName">
                  <template v-for="(e,i) in iotInfo.sceneTagName.split(',')">
                    <span v-if="i < 2" :key="i">{{e}}</span>
                  </template>
                </div>
                <p><span>分类：</span>{{iotInfo.categoryName.split(',')[0]}}</p>
                <p v-if="iotInfo.datIsv"><span>厂商：</span>{{iotInfo.datIsv.name}}</p>
              </div>
              <div class="home-iot-info-head-desc-chip">
                <p><span>开发板</span><strong @click="iotProductInfo(iotInfo.datDevboard.id,'dev')">{{iotInfo.devboardName}}</strong></p>
                <p>
                  <span>SOC</span>
                  <template v-if="iotInfo.datSocList">
                    <strong v-for="i in iotInfo.datSocList" :key="i.id" @click="iotProductInfo(i.id,'soc')">{{i.name}}</strong>
                  </template>
                </p>
                <p>
                  <span>OS</span>
                  <template v-if="iotInfo.datOsList">
                    <strong v-for="i in iotInfo.datOsList" :key="i.id" @click="iotProductInfo(i.id,'os')">{{i.name}}</strong>
                  </template>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="home-iot-info-desc">
          <div>
            <p>硬件信息</p>
            <div>
              <p><span>入网方式：</span>{{iotInfo.connMode?iotInfo.connMode:'其他'}}</p>
              <p><span>网络协议：</span>{{iotInfo.networkProtocol?iotInfo.networkProtocol:'其他'}}</p>
              <p><span>加密方式：</span>{{iotInfo.cipherMode?iotInfo.cipherMode:'其他'}}</p>
              <p><span>硬件尺寸：</span>{{iotInfo.hardwareSize?iotInfo.hardwareSize:'其他'}}</p>
            </div>
          </div>
          <div>
            <p>产品介绍</p>
            <div>
              <span v-html="iotInfo.discription"></span>
            </div>
          </div>
          <div v-if="useGuide != ''">
            <p>使用指南</p>
            <div>
              <template v-for="item in useGuide">
                <div :key="item.id">
                  <p>
                    <img alt="图片" src="@/assets/img/fjicon.png" />
                    <span>{{item.fileName}}</span>
                  </p>
                  <div>
                    <span :data-id="item.id" @click="download(item.fileName,item.id)">下载</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <template v-if="iotInfo.isHarmony == 1">
            <div v-if="iotInfo.hmAdv">
              <p>鸿蒙优势</p>
              <div>
                <span v-html="iotInfo.hmAdv"></span>
              </div>
            </div>
            <div v-if="iotInfo.hmFrameworkImg">
              <p>鸿蒙架构</p>
              <div>
                <v-img :attachment-id="iotInfo.hmFrameworkImg " :default-img="require('@/assets/img/kctp.png')" />
              </div>
            </div>
            <div v-if="iotInfo.hmCase">
              <p>鸿蒙方案</p>
              <div>
                <span v-html="iotInfo.hmCase"></span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { getIotProductInfo} from "@/api/iot";
import VImg from "@/components/VImg";
import share from '../components/share.vue'
export default {
  components:{
    VImg,
    share
  },
  computed: {
    id: function () {
      return this.$route.query.id;
    },
  },
  data(){
    return{
      iotInfo:'',
      useGuide:[],
      shareInfo: {
          title: '',
          desc: '致力于为您提供国产化IoT产品，同时通过OpenHarmony适配，实现您的IoT产品国产化升级改造。',
          img: ''
      }
    }
  },
  created () {

    document.title = '找｜硬件产品'
  },
  mounted(){
    this.loadGetIotProductInfo()
  },
  methods:{
    loadGetIotProductInfo(){
      getIotProductInfo(this.id).then((res)=>{
        if(res.hmAdv != '' && res.hmAdv != null){
          res.hmAdv = res.hmAdv.replace(/<[^>]*>/g,'')
        }
        if(res.hmCase != '' && res.hmCase != null){
          res.hmCase = res.hmCase.replace(/<[^>]*>/g,'')
        }
        this.iotInfo = res
        this.shareInfo.title = res.name
        this.shareInfo.img = `${process.env.VUE_APP_OBS_PREFIX}/${res.productImg}`
        if(res.attachmentList != ''){
          res.attachmentList.forEach(element => {
            if(element.attachmentType == '300004'){
              this.useGuide.push(element)
            }
          });
        }
      })
    },
    iotProductInfo(id,type){
      this.$router.push({
        path: "/product/productInfo",
        query: { id,type },
      });
    },
    download(name, id) {
      window.open(`${process.env.VUE_APP_API_PORTAL_URL}/file/download/${id}`);
    },
  }
};
</script>

<style lang="scss" scoped>
.home-iot-info {
  width: 100%;
  margin: 0 auto;
  padding: 0 0 24px;
  .home-iot-info-content-box {
    display: flex;
    .home-iot-info-content {
      width: 100%;
      .home-iot-info-head {
        background: #fff;
        padding: 16px;
        // display: flex;
        .home-iot-info-head-img {
            margin-bottom: 16px;
          > span {
            display: block;
            > img {
              max-width: 100%;
              display: block;
              margin: 0 auto;
            }
          }
          > div {
            display: flex;
            margin-top: 8px;
            > span {
              width: 54px;
              height: 54px;
              margin-right: 8px;
              cursor: pointer;
              > img {
                width: 100%;
                height: 100%;
                display: block;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .home-iot-info-head-detail {
          flex: 1;
          .home-iot-info-head-title {
            margin-bottom: 16px;
            > span {
              font-size: 20px;
              line-height: 24px;
              display: flex;
              align-items: center;
              >span{
                color: #fff;
                background: linear-gradient(90deg, #ea4243 0%, #ff8b4c 100%);
                font-size: 14px;
                padding: 0 12px;
                line-height: 24px;
                margin-right: 12px;
                border-radius: 2px;
                white-space: nowrap;
              }
            }
          }
          .home-iot-info-head-desc {
            display: flex;
            flex-wrap: wrap;
            .home-iot-info-head-desc-text {
              width: 100%;
              > div {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 16px;
                > span {
                  height: 20px;
                  background: #f6f6f6;
                  border-radius: 3px;
                  padding: 0 12px;
                  line-height: 20px;
                  color: #666;
                  font-size: 12px;
                  margin-right: 10px;
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
              > p {
                line-height: 16px;
                display: flex;
                margin-bottom: 9px;
                font-size: 14px;
                > span {
                  color: #999;
                }
              }
            }
            .home-iot-info-head-desc-chip {
              padding: 16px;
              background: url(../../assets/img/bg5.png) no-repeat;
              background-size: 100% 100%;
              width: 100%;
              > p {
                display: flex;
                line-height: 14px;
                margin-bottom: 20px;
                font-size: 14px;
                &:last-child {
                  margin-bottom: 0;
                }
                > span {
                  width: 74px;
                  margin-right: 10px;
                  color: #8c8c8c;
                }
                >strong{
                  font-weight: 500;
                  color: #EA0B06;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .home-iot-info-desc {
        background: #fff;
        padding: 16px;
        > div {
          margin-bottom: 24px;
          &:last-child{
            margin-bottom: 0;
          }
          > p {
            border-left: 4px solid #EA0B06;
            padding-left: 10px;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 16px;
            font-weight: bold;
          }
          > div {
            padding-left: 14px;
            display: flex;
            flex-wrap: wrap;
            > p {
              width: 100%;
              display: flex;
              line-height: 19px;
              font-size: 14px;
              margin-bottom: 9px;
              > span {
                color: #8c8c8c;
                white-space: nowrap;
              }
              &:last-child{
                margin-bottom: 0;
              }
            }
            >span{
              width: 100%;
              display: block;
              line-height: 22px;
              // text-align: justify;
              font-size: 14px;
              >img{
                max-width: 100%;
              }
              img{
                max-width: 100%;
              }
            }
            >div{
              display: flex;
              align-items: center;
              font-size: 14px;
              >p{
                display: flex;
                align-items: center;
                margin-right: 24px;
                margin-bottom: 0;
                >img{
                  margin-right: 8px;
                }
                >span{
                  max-width: 290px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  line-height: 14px;
                }
              }
              >div{
                display: flex;
                >span{
                  cursor: pointer;
                  color: #EA0B06;
                  line-height: 14px;
                  margin-right: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.home-activity-info-head .ant-breadcrumb a:hover {
  color: #EA0B06;
}
.home-iot-info-desc img{
  max-width: 100%;
}
</style>
