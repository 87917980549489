import { PortalRequest as request } from "@/utils/request";

export const getProductList = (params, data) => {
  return request({
    url: "/product/replace-page-list",
    method: "post",
    params: params,
    data,
  });
};


// 分页IOT产品管理列表
export const getIotProductList = (params, data) => {
  return request({
    url: "/product/page-list",
    method: "post",
    params: params,
    data,
  });
};

// 分页IOT产品详情
export const getIotProductInfo = (id) => {
  return request({
    url: `/product/${id}/detail`,
    method: "get",
  });
};

// 分页查询SOC
export const getIotSocList = (params, data) => {
  return request({
    url: "/soc/page-list",
    method: "post",
    params: params,
    data,
  });
};

// 分页查看开发板列表
export const getIotDevList = (params, data) => {
  return request({
    url: "/devboard/page-list",
    method: "post",
    params: params,
    data,
  });
};

// 分页查看OS列表
export const getIotOsList = (params, data) => {
  return request({
    url: "/os/page-list",
    method: "post",
    params: params,
    data,
  });
};

// 查看soc详情
export const getIotSocInfo = (id) => {
  return request({
    url: `/soc/${id}/detail`,
    method: "get",
  });
};

// os详情
export const getIotOsInfo = (id) => {
  return request({
    url: `/os/${id}/detail`,
    method: "get",
  });
};

// 查看devboard详情
export const getIotDevInfo = (id) => {
  return request({
    url: `/devboard/${id}/detail`,
    method: "get",
  });
};

// 根据各类id获得知识汇聚list
export const getIotKnowledgeList = (params, data) => {
  return request({
    url: `/knowledge/list`,
    method: "post",
    params: params,
    data,
  });
};

export const getIotKnowledgeChooseList = (params, data) => {
  return request({
    url: `/knowledge/choose-list`,
    method: "post",
    params: params,
    data,
  });
};

// 相关产品&方案
export const getDatSolutionList = (data) => {
  return request({
    url: `/solution/getDatSolutionList`,
    method: "post",
    data,
  });
};

// 根据关键字模糊查询方案，产品，sku
export const getSearchInfo = (params, data) => {
  return request({
    url: `/product/search`,
    method: "post",
    params,
    data,
  });
};

// 获取关系图数据结构
export const getGraphInfo = () => {
  return request({
    url: `/relationship-graph/data`,
    method: "get",
  });
};

// 获取关系图数据结构
export const getGraphData = (id, category) => {
  return request({
    url: `/relationship-graph/graph-data`,
    method: "get",
    params: { id, category },
  });
};

// 首页产品列表
export const getHomeProduct = (params) => {
  return request({
    url: `/product/homePage`,
    method: "post",
    params,
  });
};

// 首页开发板列表
export const getHomeDev = (params) => {
  return request({
    url: `/devboard/homePage`,
    method: "post",
    params,
  });
};

// 查看开发板列表-专辑（根据级别、专辑排序）
export const getDevAlbumList = (data) => {
  return request({
    url: `/devboard/albumList`,
    method: "post",
    data
  });
};

/**
 * 申请开发板
 * @param {*} data
 * @returns
 */
export const devboardApply = (data) => {
  return request({
    url: `/devboard-apply/save`,
    method: "post",
    data
  });
};
